<template>
  <div class="title">
    <div class="title__name" v-text="options.name"/>
    <div class="title__actions flex">
      <label v-if="options.id === 'catalog'" style="padding: 0 10px">
        <div class="btn btn-div">Импорт из файла</div>
        <input type="file" ref="fileExcel" name="excel" @change="uploadFile">
      </label>

<!--      <label v-if="options.id === 'catalog'">-->
<!--        <div class="btn btn-div">Пакетное обновление треков</div>-->
<!--        <input type="file" ref="fileJson" name="excel" @change="uploadJsonFile">-->
<!--      </label>-->
<!--      <button-->
<!--          v-if="options.id === 'catalog'"-->
<!--          class="btn"-->
<!--          @click="exportSounds"-->
<!--          v-text="'Экспортировать в эксель'"-->
<!--      />-->

      <a :href="exportUrl" class="btn">Скачать Excel</a>
      <button
        v-if="buttonText"
        class="btn btn-gradient"
        @click="createModal({ modal })"
        v-text="buttonText"
      />

      <button
        v-if="(options.actions || '').includes('add')"
        class="btn btn-add btn-gradient"
        @click="createModal({ modal })"
        v-text="options.add"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CommonTitle',

  props: {
    options: {
      type: Object,
    }
  },

  data: () => ({
    exportUrl: "/api/excel/export",
    file: null,
    buttons: {
      admin: 'Добавить администратора',
      catalog: 'Добавить песню',
      stock: 'Добавить розыгрыш',
      prize: 'Добавить приз',
      library: 'Добавить песню',
      newsfeed: "Добавить новость",
      'stock/prizes': 'Добавить приз',
      // 'stock/members': 'Добавить игрока',
    },
  }),

  computed: {
    ...mapGetters(['token']),
    modal() {
      return this.options.modal || `${ this.options.id }-add`;
    },
    buttonText() {
      return this.options.button || this.buttons[this.options.id];
    },
  },

  methods: {
    ...mapActions(['createModal']),
    async uploadFile() {
      try {

        this.file = this.$refs.fileExcel.files[0];
        const formData = new FormData();
        formData.append('excel', this.file);
        const headers = { token: this.token };
        const response = await axios.post(`/api/excel`, formData, { headers });
        location.reload();
      } catch(err) {
        console.error(err);
      };
    },
    async uploadJsonFile(){
      try {
        this.file = this.$refs.fileJson.files[0];
        const formData = new FormData();
        formData.append('sounds', this.file);
        const headers = { token: this.token };
        const response = await axios.post(`/api/update`, formData, { headers });
        this.$bus.$emit('showNotification', {message: 'Треки обновлены', status: 'success'});
        location.reload();
      } catch(err) {
        console.error(err);
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  &__name {
    color: #00325C;
    font-size: 22px;
    font-weight: 500;
  }
  &__actions {
    button:last-child {
      margin-left: 15px;
    }
  }
}
</style>