<template>
  <svg 
    fill="none" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <path 
      :fill="localColor"
      d="M12.3214 7.49998C12.0255 7.49998 11.7857 7.73982 11.7857 8.0357V13.3928C11.7857 13.6887 11.5458 13.9285 11.25 13.9285H1.60714C1.31126 13.9285 1.07142 13.6887 1.07142 13.3928V2.67855C1.07142 2.38267 1.31126 2.14283 1.60714 2.14283H8.03571C8.33158 2.14283 8.57143 1.90298 8.57143 1.6071C8.57143 1.31123 8.33158 1.07141 8.03571 1.07141H1.60714C0.719541 1.07141 0 1.79095 0 2.67855V13.3928C0 14.2804 0.719541 15 1.60714 15H11.25C12.1376 15 12.8571 14.2804 12.8571 13.3928V8.03567C12.8571 7.73982 12.6173 7.49998 12.3214 7.49998Z"/>
    <path 
      :fill="localColor"
      d="M14.4 0.60074C14.0153 0.216031 13.4936 -5.43034e-05 12.9496 8.47551e-06C12.4053 -0.001561 11.883 0.214901 11.4994 0.601085L4.44297 7.65694C4.38443 7.71592 4.34026 7.78761 4.31387 7.8664L3.24245 11.0807C3.14894 11.3614 3.30071 11.6647 3.58142 11.7582C3.63588 11.7764 3.69292 11.7856 3.7503 11.7857C3.8078 11.7856 3.86496 11.7764 3.91958 11.7584L7.13387 10.687C7.21281 10.6606 7.28454 10.6162 7.34333 10.5573L14.3998 3.50091C15.2007 2.7001 15.2008 1.40164 14.4 0.60074ZM13.6423 2.74392L6.67797 9.70821L4.59725 10.403L5.28992 8.32497L12.2569 1.36071C12.6399 0.97845 13.2603 0.979078 13.6426 1.36209C13.8252 1.54506 13.9281 1.79276 13.9289 2.05125C13.9295 2.31112 13.8263 2.56048 13.6423 2.74392Z"/>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconEdit',
  mixins: [iconMixin],
};
</script>