<template>
  <div class="common__table table">

    <table :style="{
      '--template-columns': options.ths.map(th => th.width.replace('%', 'fr')).join(' '),
    }">
      <thead>
        <tr>
          <th v-for="th of options.ths" :key="th.id">
            <div class="top">
              <div class="name" v-if="th.hint" @mouseover="titleHint(th, true)" @mouseout="titleHint(th, false)">{{ th.name }}</div>
              <div class="hint" v-if="th.show">{{ th.fullname }}</div>
              <div class="name" v-if="!th.hint">{{ th.name }}</div>
              <div v-if="th.sort" class="flex direction_column">
                <svg @click="selectSortAndSearch(th, 'max')" width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 4L3.5 1L1 4" stroke="#6461F6" stroke-width="1.28571" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg @click="selectSortAndSearch(th, 'min')" width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L3.5 4L6 1" stroke="#6461F6" stroke-width="1.28571" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div v-if="th.search" class="bottom">
              <input type="text" v-model="th.value" @keyup.enter="selectSortAndSearch(th)">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4.5" cy="4.5" r="3.5" stroke="#E2E1EF" stroke-width="1.5"/>
                <path d="M7 7L10 10" stroke="#E2E1EF" stroke-width="1.5"/>
              </svg>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="options.table.data">
        <template v-for="(element, elementIndex) of options.table.data">
          <tr :key="element._id + '_main'">
            <td
              v-for="(variable, variableIndex) of options.table.variables" 
              :key="[variable, element._id, elementIndex].join('_')"
            >
              <div
                style="display: inline-block; width: 100%;"
                v-html="optionsForData(
                  lodash.get(element, variable),
                  variable,
                  options.id,
                  element,
                  options.ths.find(th => th.id === variable),
                  elementIndex,
                )"
              />
              <IconCaretDownSquareFill
                v-if="getSubTitleName(variable)"
                ref="iconDown"
                width="16"
                height="16"
                :key="[variable, element._id, elementIndex, options.table.activeLastUpdate].join('_')"
                :color="isActiveChild(getSubTitleName(variable), elementIndex) ? '#D885FF' : '#6461F6'"
                @click.stop="$emit('set-child', {
                  name: getSubTitleName(variable),
                  index: elementIndex,
                })"
              />
            </td>
            <td style="text-align: right;">
              <IconRemove
                v-if="options.actions.includes('remove')"
                ref="iconRemove"
                width="14"
                height="16"
                color="#F84646"
                @click.stop="createModal(`${options.id}-remove`, element)"
              />
              <IconEdit
                v-if="options.actions.includes('edit')"
                ref="iconEdit"
                width="16"
                height="16"
                color="#B5B4D6"
                @click.stop="createModal(`${options.id}-edit`, element)"
              />   
            </td>
          </tr>
          <tr 
            v-if="isActiveChild(options.table.activeChild, elementIndex)"
            :key="[element._id, 'subtable', options.table.activeLastUpdate].join('_')"
            class="subtable"
            :style="{ 
              'grid-template-columns': '1fr !important',
              '--border-color': options.table.activeChildColor || '#EB7AFF',
            }"
          >
            <CommonTitle
              :options="{
                name: lodash.get(options.ths.find(th => th.child === options.table.activeChild), 'name'),
                id: `stock/${ options.table.activeChild }`,
              }"
            />
            <CommonTable
              @create-modal="$emit('create-modal', $event)"
              :options="{
                ...lodash.get(options.table.childred, options.table.activeChild, {}),
                table: { 
                  ...lodash.get(options.table.childred, options.table.activeChild, { table: {}}).table,
                  data: lodash.get(element, lodash.get(options.table.childred, options.table.activeChild, {dir: null}).dir, []),
                } 
              }"
            />
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr v-for="(el, index) of 3" :key="index">
          <td 
            v-for="variable of options.table.variables" 
            :key="variable" 
          >
            <div class="preloader"></div>
          </td>
          <td>
            <div class="preloader"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginate" :class="{end: options.table.total <= options.table.limit}">
      <div class="pages" v-if="options.table.total > options.table.limit">
        <div class="page" :aria-test="options.table.total" :aria-test2="options.table.limit" @click="selectSortAndSearch(null, null, index, number, true)" :class="{selected: number === options.table.page}" v-for="(number, index) of Math.ceil(options.table.total / options.table.limit)" :key="index">
          {{ number }}
        </div>
      </div>
      <div class="limits" v-if="options.table.total > 10">
        <div class="limit" :class="{selected: limit.value == options.table.limit}" @click="selectSortAndSearch(null, null, 0, 1, false, limit.value, true)" v-for="limit of limits" :key="limit.value">
          {{ limit.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import optionsForData from '@/utils/options';
import Axios from 'axios';
import _ from 'lodash';

import IconCaretDownSquareFill from '@/components/icons/IconCaretDownSquareFill';
import IconRemove from '@/components/icons/IconRemove';
import IconEdit from '@/components/icons/IconEdit';

import CommonTitle from './CommonTitle';

export default {
  name: 'CommonTable',

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  components: {
    IconCaretDownSquareFill,
    IconRemove,
    IconEdit,
    CommonTitle,
  },

  data: () => ({
    lodash: _,
    prev_options: null,
    limits: [
      {selected: false, value: 10, text: '10'},
      {selected: false, value: 50, text: '50'},
      {selected: true, value: 100, text: '100'},
      {selected: false, value: 1000, text: 'Все'},
    ]
  }),

  methods: {
    optionsForData,
    titleHint(th, status) {
      th.show = status;
    },
    selectSortAndSearch(th = null, sort = null, index = 0, number = 1, paginateClick = false, limit = 10, limitClick = false) {
      const options = {};
      if (this.options.table.page === number && paginateClick) return;
      if (sort) {
        this.options.ths.forEach(el => el.sort_data = null);
        options[th.id + '_sort'] = sort;
        th.sort_data = sort;
      }
      if (th) {
        this.options.table.page = 0;
      }
      if (paginateClick) {
        this.options.table.page = index;
      }
      if (limitClick) {
        this.options.table.limit = limit;
        this.options.table.page = 0;
      }

      const sort_el = this.options.ths.find(el => el.sort_data);
      if (sort_el) options[sort_el.id + '_sort'] = sort_el.sort_data;

      this.options.ths.forEach(el => {
        if (el.id) options[el.id] = el.value;
      })
      options.page = this.options.table.page;
      options.limit = this.options.table.limit;

      Axios
        .get(`/api/${this.options.request}`, {
          headers: {
            token: this.$store.getters.token
          },
          params: options
        })
        .then(res => {
          this.options.table.data = res.data.data;
          this.options.table.page = number;
          this.options.table.total = res.data.total;
        })
        .catch(err => {
          console.error(err.response);
        })
    },
    createModal(modal, element) {
      this.$emit('create-modal', { modal, element });
      this.$store.dispatch('createModal', { modal, element });
    },

    getSubTitleName(variable) {
      return _.get(this.options.ths.find(th => th.id === variable), 'child');
    },
    isActiveChild(variable, index, abc) {
      const isValidName = this.options.table.activeChild === variable;
      const isValidIndex = this.options.table.activeChildIndex === index;
      return isValidName && isValidIndex;
    },
  }
}
</script>

<style lang="scss">
@keyframes pulsate {
  50% {
    background-color: #FAFAFD;
  }
}

.common__table.table {
  width: 100%;
  padding: 0 25px;
  padding-top: 0;
  padding-bottom: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  table {
    width: 100%;
    tr {
      display: grid;
      grid-template-columns: var(--template-columns);
      gap: 10px;
    }
    thead {
      tr {
        th {
          text-align: left;
          display: grid;
          align-items: center;
          align-content: space-around;
          .top {
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
            .hint {
              position: absolute;
              top: 30px;
              left: 0;
              background-color: #fff;
              border-radius: 10px;
              border: 2px solid #F8F8FB;
              color: #00325C;
              padding: 10px 15px;
              z-index: 1;
            }
            .name {
              font-size: 12px;
              color: #00325C;
              font-weight: 600;
              text-transform: uppercase;
              word-break: break-all;
              white-space: break-spaces;
            }
            > div {
              svg {
                width: 10px;
                height: 10px;
                position: relative;
                right: 2px;
                cursor: pointer;
              }
            }
          }
          .bottom {
            position: relative;
            input {
              border: 2px solid #F8F8FB;
              border-radius: 30px;
              width: 100%;
              outline: none;
              padding: 5px 0;
              padding-left: 25px;
              background-color: #fff;
            }
            svg {
              position: absolute;
              width: 14px;
              height: 14px;
              top: 9px;
              left: 10px;
            }
          }
        }
      }
    }
    tbody {
      border-top: 1px solid #E6E5F1;
      tr {
        &:not(:last-child) {
          border-bottom: 2px dashed #ededf5;
        }
        &.subtable {
          box-shadow: inset 0 0 0 2px var(--border-color); 
          background-color: #F4F5F9; 
          border-radius: 12px; 
        }
        td {
          padding: 13px 5px;
          font-size: 14px;
          color: #00325C;
          font-weight: 500;
          vertical-align: top;
          // display: grid;
          &:last-child {
            grid-template-columns: repeat(2, 1fr);
          }
          .preloader {
            width: 50%;
            height: 15px;
            margin: 0 auto;
            border-radius: 10px;
            background-color: #f4f5f9d8;
            animation: pulsate 2s ease infinite;
          }
          div.content {
            width: 95%;
            display: -webkit-box;
            // -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            word-break: break-all;
            &.stock-status {
              color: white;
              cursor: help;
              background-color: #6461F6;
              display: flex;
              width: 100%;
              height: 100%;
              text-align: center;
              justify-content: center;
              align-items: center;
              padding: 10px;
              border-radius: 3px;
              font-weight: 600;
            }
            &.green {
              color: #17B08B;
            }
            &.red {
              color: #F84546;
            }
            &.blue {
              color: #6461F6;
            }
            &.img {
              --size: 512px;
              width: 100%;
              background-color: rgb(230, 230, 230);
              border-radius: 15px;
              overflow: hidden;
              line-height: 0;
              &::before {
                content: "";
                background-size: var(--size);
                background-image: var(--data-src);
                background-color: #6461F644;
                backdrop-filter: blur(2px);
                border-radius: 15px;
                box-shadow: 0 0 0 2px #6461F6, 0 20px 40px #0004;
                display: none;
                width: var(--size);
                height: var(--size);
                position: fixed;
                top: calc(50vh - var(--size) / 2);
                left: calc(50vw - var(--size) / 2);
              }
              &:hover::before {
                display: flex;
              }
              > img {
                max-width: 100%;
                border-radius: 15px;
                padding: 2px;
                z-index: 1;
              }
            }
          }
          svg {
            width: 18px;
            height: 18px;
            position: relative;
            top: 2px;
            cursor: pointer;
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .paginate {
    display: flex;
    justify-content: space-between;
    &.end {
      justify-content: flex-end;
    }
    .pages, .limits {
      display: flex;
      .page, .limit {
        width: 10px;
        margin: 10px;
        cursor: pointer;
        color: #B5B4D6;
        &.selected {
          color: #00325C;
        }
      }
    }
    .limits {
      .limit {
        width: 15px;
      }
    }
  }
}
</style>