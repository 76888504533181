import zeroFilter from '../filters/zeroPad.filter';
import dateServerFilter from '../filters/date.server.filter';
import { statuses as stockStatuses } from '@/constants/stock.js';
import { categories as prizeCategories } from '@/constants/prize.js';

const sound_status = [
  {id: 'Активна', name: 'Активна', color: 'green'},
  {id: 'Неактивна', name: 'Неактивна', color: 'red'},
];

export default function (data, variable, table_id, element, options, index) {

  if (variable === '#') return `${ index + 1 }.`;

  if (variable === 'published'){
      return new Date(data).toLocaleString("ru-Ru", {timeZone: 'Europe/Moscow'});
  }

  if (variable === 'guessing_time' && table_id === 'sounds') {
    if (element.total_time)
      return `<div class="content">${Math.ceil(element.total_time / element.total_playing)}</div>`;
    else 
      return `<div class="content">0</div>`; 
  }
  if (variable === 'time' && table_id === 'speed') {
    return `<div class="content">от ${element.min_time} сек до ${element.max_time} сек</div>`;
  }

  if (data == null) {
    return `<div class="content"></div>`;
  }
  if (variable === 'img' && table_id === 'catalog') {
    return `<div class="content">${data ? 'Да' :  'Нет'}</div>`;
  }
  if (variable === 'sound' && table_id === 'catalog') {
    return `<div class="content">${data ? 'Да' :  'Нет'}</div>`;
  }
  if (variable === 'status_id' && (table_id === 'catalog' || table_id === 'library')) {
    const status = sound_status.find(el => el.id === data);
    if (status) {
      return `<div class="content"><span class="${status.color}">${status.name}</span></div>`;
    }
  }
  if (variable === 'sound_id' && (table_id === 'catalog' || table_id === 'sounds')) {
    return `<div class="content">${zeroFilter(data, 6)}</div>`;
  }
  if (variable === 'quantity_currect_answer' && table_id === 'sounds') {
    return `<div class="content">${element.quantity_currect_answer}</div>`;
  }
  if (variable === 'quantity_helps' && table_id === 'sounds') {
    return `<div class="content">${element.quantity_helps}</div>`;
  }
  if (variable === 'creation_date' && table_id === 'user') {
    return `<div class="content">${dateServerFilter(data, 'datetime')}</div>`;
  }
  if (variable === 'sound' && table_id === 'catalog') {
    return `<div class="content">
      <a href="${data}" target="_blank">
        <span class="blue">${data}</span>
      </a>
    </div>`;
  }
  if (variable === 'difficulty_id' && table_id === 'catalog') {
    return `<div class="content">${data.name}</div>`;
  }
  if (variable === 'filter_id' && table_id === 'catalog') {
    return `<div class="content">
      <div>${data.name}</div>
      <div>${data.description}</div>
    </div>`;
  }
  if (variable === 'winner_id' && table_id === 'stock') {
    return `<div class="content">${data.login}</div>`;
  }
  if (['end_date', 'start_date'].includes(variable) && table_id === 'stock') {
    return `<div class="content">${dateServerFilter(data)}</div>`;
  }
  if ((variable === 'genre_id' || variable === 'language_id' || variable === 'executor_id' || variable === 'text_author_id' || variable === 'music_author_id' ) && (table_id === 'catalog' || table_id === 'library')) {
    return `<div class="content">${data.map(temp => temp.name).join(', ')}</div>`;
  }
  if (variable === 'film_id' && table_id === 'catalog') {
    return `<div class="content">${data.name}</div>`;
  }

  if (options.type === 'image') {
    return `<div class="content img" style="--data-src: url('${ data }')"><img src="${ data }"/></div>`;
  };
  
  if (variable === 'img') {
    return `<div class="content">
      <div style="width: 100%" class="img">
        <img src="${data}" alt="">
      </div>
    </div>`; 
  }
  if (variable === 'score' && table_id === 'score') {
    return `<div class="content">${data > 0 ? `+${data}` : data}</div>`;
  }

  if (variable === 'status' && table_id === 'stock') {
    const options = stockStatuses.find(s => [s._id, s.name].includes(data));
    if (options) {
      return `<div 
        class="content stock-status" 
        title="${options.title}" 
        style="background-color: ${options.color}"
      >${options.name}</div>`;
    };
  };

  if (variable === 'category' && table_id === 'stock/prizes') {
    const options = prizeCategories.find(s => [s._id, s.name].includes(data));
    if (options) {
      return `<div 
        class="content" 
        title="${options.title}" 
      >${options.name}</div>`;
    };
  };

  if (table_id === 'stock' && variable === 'fullname') {
    return `<div class="content" style="display: grid;">
      <a 
        style="color: #6461F6; cursor: help; font-size: 0.8em" 
        title="ID розыгрыша"
        target="_blank" 
        href="/api/analytics/stock?stock_id=${element._id}"
      >#${element._id}</a>
      <span title="${data}">${data}</span>
    </div>`;
  };

  // console.log({data, variable, table_id, element, options});

  return `<div class="content" title="${data}">${data}</div>`;
}