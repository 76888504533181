<template>
  <svg 
    fill="none" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <path 
      :fill="localColor"
      d="M11.0156 1.875H8.4375V1.40625C8.4375 0.63085 7.80665 0 7.03125 0H5.15625C4.38085 0 3.75 0.63085 3.75 1.40625V1.875H1.17188C0.525703 1.875 0 2.4007 0 3.04688V4.6875C0 4.94637 0.209883 5.15625 0.46875 5.15625H0.724922L1.12989 13.6606C1.16566 14.4117 1.78266 15 2.53453 15H9.65297C10.4049 15 11.0219 14.4117 11.0576 13.6606L11.4626 5.15625H11.7188C11.9776 5.15625 12.1875 4.94637 12.1875 4.6875V3.04688C12.1875 2.4007 11.6618 1.875 11.0156 1.875ZM4.6875 1.40625C4.6875 1.14779 4.89779 0.9375 5.15625 0.9375H7.03125C7.28971 0.9375 7.5 1.14779 7.5 1.40625V1.875H4.6875V1.40625ZM0.9375 3.04688C0.9375 2.91765 1.04265 2.8125 1.17188 2.8125H11.0156C11.1449 2.8125 11.25 2.91765 11.25 3.04688V4.21875C11.1055 4.21875 1.53612 4.21875 0.9375 4.21875V3.04688ZM10.1212 13.616C10.1092 13.8664 9.90357 14.0625 9.65297 14.0625H2.53453C2.2839 14.0625 2.07823 13.8664 2.06634 13.616L1.66348 5.15625H10.524L10.1212 13.616Z"/>
    <path 
      :fill="localColor"
      d="M6.09375 13.125C6.35262 13.125 6.5625 12.9151 6.5625 12.6562V6.5625C6.5625 6.30363 6.35262 6.09375 6.09375 6.09375C5.83488 6.09375 5.625 6.30363 5.625 6.5625V12.6562C5.625 12.9151 5.83485 13.125 6.09375 13.125Z"/>
    <path 
      :fill="localColor"
      d="M8.4375 13.125C8.69637 13.125 8.90625 12.9151 8.90625 12.6562V6.5625C8.90625 6.30363 8.69637 6.09375 8.4375 6.09375C8.17863 6.09375 7.96875 6.30363 7.96875 6.5625V12.6562C7.96875 12.9151 8.1786 13.125 8.4375 13.125Z"/>
    <path 
      :fill="localColor"
      d="M3.75 13.125C4.00887 13.125 4.21875 12.9151 4.21875 12.6562V6.5625C4.21875 6.30363 4.00887 6.09375 3.75 6.09375C3.49113 6.09375 3.28125 6.30363 3.28125 6.5625V12.6562C3.28125 12.9151 3.4911 13.125 3.75 13.125Z"/>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconRemove',
  mixins: [iconMixin],
};
</script>